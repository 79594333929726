//@ts-nocheck
import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {Transition, Transitioning} from 'react-native-reanimated';

const SPACING = 8;

const transition = (
  <Transition.Change interpolation="easeInOut" durationMs={400} />
);
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  flexibleContainer: {
    flex: 1,
    maxWidth: '100%',
    margin: SPACING,
  },
});

interface RowProps {
  children: any;
  animatable?: boolean;
  spacing?: number;
  containerStyle?: ViewStyle;
}

export const Row = React.forwardRef(
  (props: RowProps, ref?: any): JSX.Element => {
    const {children, animatable, spacing, containerStyle} = props;

    const layouts = {
      row: {
        id: 'column',
        name: 'Column',
        container: {
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    };

    const _renderChildren = () => {
      children &&
        Array.isArray(children) &&
        children.map((child, index) => (
          <View
            style={StyleSheet.flatten([styles.flexibleContainer])}
            key={index}
          >
            {child}
          </View>
        ));
      if (children && !Array.isArray(children)) {
        return (
          <View style={StyleSheet.flatten([styles.flexibleContainer])}>
            {children}
          </View>
        );
      }
      return children;
    };
    if (animatable) {
      return (
        <>
          <Transitioning.View
            style={[layouts.row.container, containerStyle, styles.container]}
            {...{transition, ref}}
          >
            {_renderChildren()}
          </Transitioning.View>
        </>
      );
    }
    return (
      <>
        <View style={[containerStyle, styles.container, layouts.row.container]}>
          {_renderChildren()}
        </View>
      </>
    );
  },
);
