"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports.default = void 0;

var _Modal = _interopRequireDefault(require("./Modal"));

var _default = _Modal.default;
exports.default = _default;
module.exports = exports.default;