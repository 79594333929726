import {LinearGradient} from 'expo-linear-gradient';
import React from 'react';
import {
  ActivityIndicatorProps,
  TextProps,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {Button, IconProps} from 'react-native-elements';

export enum ButtonType {
  Solid = 'solid',
  Clear = 'clear',
  Outline = 'outline',
}

interface AppProps {
  buttonStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  disabled?: boolean;
  disabledStyle?: ViewStyle;
  disabledTitleStyle?: TextStyle;
  icon?: IconProps | any;
  iconContainerStyle?: ViewStyle;
  iconRight?: boolean;
  linearGradientProps?: any;
  loading?: boolean;
  loadingProps?: ActivityIndicatorProps;
  loadingStyle?: ViewStyle;
  onPress?(): void;
  raised?: boolean;
  title?: string;
  titleProps?: TextProps;
  titleStyle?: TextStyle;
  type?: ButtonType;
  TouchableComponent?: any;
  ViewComponent?: any;
}

export const ButtonV = (props: AppProps): JSX.Element => {
  const {
    buttonStyle,
    containerStyle,
    disabled,
    disabledStyle,
    disabledTitleStyle,
    icon,
    iconContainerStyle,
    iconRight,
    linearGradientProps,
    loading,
    loadingProps,
    loadingStyle,
    onPress,
    raised,
    title,
    titleProps,
    titleStyle,
    type,
    TouchableComponent,
    ViewComponent,
  } = props;
  return (
    <Button
      buttonStyle={buttonStyle && buttonStyle}
      containerStyle={containerStyle && containerStyle}
      disabled={disabled}
      disabledStyle={disabledStyle && disabledStyle}
      disabledTitleStyle={disabledTitleStyle && disabledTitleStyle}
      icon={icon && icon}
      iconContainerStyle={iconContainerStyle && iconContainerStyle}
      iconRight={iconRight && iconRight}
      linearGradientProps={linearGradientProps && linearGradientProps}
      loading={loading}
      loadingProps={loadingProps && loadingProps}
      loadingStyle={loadingStyle && loadingStyle}
      onPress={onPress && onPress}
      raised={raised && raised}
      title={title && title}
      titleProps={titleProps && titleProps}
      titleStyle={titleStyle && titleStyle}
      type={type && type}
      TouchableComponent={TouchableComponent}
      ViewComponent={
        linearGradientProps
          ? LinearGradient
          : ViewComponent
          ? ViewComponent
          : View
      }
    />
  );
};
