//@ts-nocheck
import React from 'react';
import {Dimensions, StyleSheet, View} from 'react-native';
import {Transition, Transitioning} from 'react-native-reanimated';

const {width} = Dimensions.get('window');

const SPACING = 8;

const transition = (
  <Transition.Change interpolation="easeInOut" durationMs={400} />
);
const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  flexibleContainer: {
    flex: 1,
    maxWidth: '100%',
    margin: SPACING,
  },
});

interface RealSmartContainerVProps {
  children: any;
  layout: 'row' | 'column' | 'wrap';
  animatable?: boolean;
  spacing?: number;
}

export const RealSmartContainerV = React.forwardRef(
  (props: RealSmartContainerVProps, ref?: any): JSX.Element => {
    const {children, layout, animatable, spacing} = props;

    const layouts = {
      row: {
        id: 'column',
        name: 'Column',
        container: {
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      column: {
        id: 'row',
        name: 'Row',
        container: {
          flexDirection: 'row',
          alignItems: 'center',
        },
      },
      wrap: {
        id: 'wrap',
        name: 'Wrap',
        container: {
          flexDirection: 'row',
          flexWrap: 'wrap',
        },
        child: {
          flex: 0,
          width: width / 2 - (spacing ?? SPACING) * 2,
        },
      },
    };

    const _renderChildren = () => {
      children?.map((child, index) => (
        <View
          style={StyleSheet.flatten([
            styles.flexibleContainer,
            layouts[layout]?.child,
          ])}
          key={index}
        >
          {child}
        </View>
      ));
      return children;
    };
    if (animatable) {
      return (
        <>
          <Transitioning.View
            style={[styles.container, layouts[layout]?.container]}
            {...{transition, ref}}
          >
            {_renderChildren()}
          </Transitioning.View>
        </>
      );
    }
    return (
      <>
        <View style={[styles.container, layouts[layout]?.container]}>
          {_renderChildren()}
        </View>
      </>
    );
  },
);
