import * as React from 'react';
import {ViewStyle, TextStyle, TextProps} from 'react-native';

import {ListItem} from 'react-native-elements';

interface ListItemVProps {
  Component?: any;
  containerStyle?: ViewStyle;
  contentContainerStyle?: ViewStyle;
  rightContentContainerStyle?: ViewStyle;
  chevron?: boolean | any;
  checkmark?: boolean | any;
  onPress?(): void;
  onLongPress?(): void;
  title?: string;
  titleStyle?: TextStyle;
  titleProps?: TextProps;
  subtitle?: string;
  subtitleStyle?: TextStyle;
  subtitleProps?: TextProps;
  rightTitle?: string;
  rightTitleStyle?: TextStyle;
  rightTitleProps?: TextProps;
  rightSubtitle?: string;
  rightSubtitleStyle?: TextStyle;
  rightSubtitleProps?: TextProps;
  leftIcon?: any;
  rightIcon?: any;
  leftAvatar?: any;
  rightAvatar?: any;
  leftElement?: React.ReactElement;
  rightElement?: React.ReactElement;
  switch?: any;
  input?: any;
  buttonGroup?: any;
  checkBox?: any;
  badge?: any;
  disabled?: boolean;
  disabledStyle?: ViewStyle;
  topDivider?: boolean;
  bottomDivider?: boolean;
  ViewComponent?: any;
  pad?: number;
}

export const ListItemV = (props: ListItemVProps): JSX.Element => {
  return <ListItem {...props} />;
};
