'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var Rating = _interopDefault(require('@material-ui/lab/Rating'));
var React = _interopDefault(require('react'));
var reactFlexboxGridRs = require('react-flexbox-grid-rs');
var reactNative = require('react-native');
var reactNativeElements = require('react-native-elements');
var rsuite = require('rsuite');
var titleCase = require('title-case');

//@ts-nocheck
class TextV extends React.Component {
    render() {
        const typography = styles.typography[this.props.type];
        const color = (() => {
            if (this.props.color) {
                return this.props.color;
            }
            else if (typography.color && typeof typography.color === 'string') {
                return typography.color;
            }
        })();
        const computedStyle = [typography, { textAlign: this.props.align, color }];
        this.props.style && computedStyle.push(this.props.style);
        return React.createElement(reactNativeElements.Text, Object.assign({}, this.props, { style: computedStyle }));
    }
}
TextV.defaultProps = {
    type: 'body',
    color: '#000000',
    align: 'left',
};
// export const TextV = (props: AppProps): JSX.Element => {
//   const typography = styles.typography[props.type];
//   const color = (() => {
//     if (props.color) {
//       return props.color;
//     } else if (typography.color && typeof typography.color === "string") {
//       return typography.color;
//     }
//   })();
//   const computedStyle = [typography, { textAlign: props.align, color }];
//   props.style && computedStyle.push(props.style);
//   return <Text {...props} style={computedStyle} />;
// };
const styles = {
    typography: {
        body: {
            fontSize: 17,
            lineHeight: 20,
        },
        callout: {
            fontSize: 16,
            lineHeight: 20,
        },
        caption: {
            fontSize: 11,
            lineHeight: 13,
        },
        footnote: {
            fontSize: 13,
            lineHeight: 18,
            color: '#999999',
        },
        headline: {
            fontSize: 17,
            lineHeight: 22,
        },
        subhead: {
            fontSize: 15,
            lineHeight: 20,
        },
        title1: {
            fontSize: 34,
            lineHeight: 41,
        },
        title2: {
            fontSize: 28,
            lineHeight: 34,
        },
        title3: {
            fontSize: 22,
            lineHeight: 26,
        },
    },
};

//@ts-nocheck
const FeedbackCardV = ({ cardsData, colspan, containerStyle, panelStyle, noOfTitleLines, titleTextStyle, countTextStyle, onCardClick }) => {
    const styles = {
        containerStyle: {
            justifyContent: 'space-around'
        },
        panelStyle: {
            margin: 10,
            alignItems: 'center',
            cursor: 'pointer'
        },
        titleStyle: {
            textAlign: 'center',
            display: 'block'
        },
        countStyle: {
            fontWeight: 'bold',
            display: 'block'
        },
        textContainerStyle: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around'
        }
    };
    const _getRate = (item) => {
        var _a;
        // console.log('rate', item.rating)
        if ((item === null || item === void 0 ? void 0 : item.rating) > 0) {
            return (React.createElement(Rating, { readOnly: true, name: "read-only", value: (_a = item === null || item === void 0 ? void 0 : item.rating) !== null && _a !== void 0 ? _a : 0, precision: 0.5 }));
        }
        else
            return null;
    };
    const _getCards = () => {
        let cards = [];
        cardsData && cardsData.forEach((item, index) => {
            cards.push(React.createElement(reactFlexboxGridRs.FBItemV, { colspan: 4 },
                React.createElement(rsuite.Panel, { shaded: true, style: reactNative.StyleSheet.flatten([
                        panelStyle, styles.panelStyle, { background: item === null || item === void 0 ? void 0 : item.backgroundColor }
                    ]), onClick: () => onCardClick(item) },
                    React.createElement(reactNative.View, { style: styles.textContainerStyle },
                        React.createElement(TextV, { type: 'title1', children: typeof item.title === 'string' &&
                                titleCase.titleCase(item.title), numberOfLines: noOfTitleLines !== null && noOfTitleLines !== void 0 ? noOfTitleLines : 1, align: 'center', style: reactNative.StyleSheet.flatten([
                                titleTextStyle,
                                styles.titleStyle
                            ]) }),
                        React.createElement(TextV, { type: 'title1', children: typeof item.count === 'number' &&
                                item.count, numberOfLines: 1, align: 'center', style: reactNative.StyleSheet.flatten([
                                countTextStyle,
                                styles.countStyle
                            ]) })),
                    React.createElement(reactNative.View, { style: { alignItems: 'center' } }, _getRate(item)))));
        });
        return (React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([containerStyle, styles.containerStyle]) },
            React.createElement(reactFlexboxGridRs.FBGridV, { justify: 'center' }, cards)));
    };
    return (React.createElement(React.Fragment, null, _getCards()));
};

exports.FeedbackCardV = FeedbackCardV;
