'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactNative = require('react-native');
require('rsuite/dist/styles/rsuite-default.css');
var Carousel = _interopDefault(require('rsuite/lib/Carousel'));

//@ts-nocheck
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
function renameKeys(obj, newKeys, delete_keys) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    const ret = Object.assign({}, ...keyValues);
    delete_keys && deleteKeys(ret, delete_keys);
    return ret;
}
const mapper = { carouselStyle: 'style' };
const delete_keys = ['imageStyle', 'images'];
const styles = {
    imageStyle: {
        height: 250,
    },
};
const CarouselV = (props) => {
    const _props = Object.assign({}, props);
    const _widgetProps = renameKeys(_props, mapper);
    deleteKeys(_widgetProps, delete_keys);
    const _getItems = () => {
        const renderedImage = [];
        props.images &&
            props.images.forEach(image => {
                renderedImage.push(React.createElement("img", { src: image.image, style: reactNative.StyleSheet.flatten([styles.imageStyle, props.imageStyle]) }));
            });
        return renderedImage;
    };
    return (React.createElement(Carousel, Object.assign({}, props, { style: reactNative.StyleSheet.flatten([props.carouselStyle]) }), _getItems()));
};

exports.CarouselV = CarouselV;
