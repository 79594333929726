'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = _interopDefault(require('react'));
var reactFlexboxGridRs = require('react-flexbox-grid-rs');
var reactIconRs = require('react-icon-rs');
var reactNative = require('react-native');
require('rsuite/dist/styles/rsuite-default.css');
var Panel = _interopDefault(require('rsuite/lib/Panel'));

//@ts-nocheck
const ids = require('short-id');
const COLOR1 = '#8A2387';
const COLOR2 = '#F27121';
const DashboardCardsV = (props) => {
    const styles = {
        iconStyle: {
            display: 'block',
            alignItems: 'center',
        },
        navItemStyle: {
            alignItems: 'center',
            textAlign: 'center',
        },
        statsStyle: {
            border: '5px solid #fff',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            width: 150,
            height: 150,
            color: '#fff',
            margin: 10
        },
        cardStyle: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            textAlign: 'center',
            color: '#fff'
        },
        titleStyle: {
            fontSize: 18,
        },
    };
    const _getCards = (items) => {
        let cards = [];
        let cols = parseInt(24 / items.length);
        console.log('column', cols);
        if (items && Array.isArray(items))
            items.forEach((ele, i) => {
                cards.push(React.createElement(reactFlexboxGridRs.FBItemV, { colspan: cols < 4 ? 4 : cols, style: reactNative.StyleSheet.flatten([{ paddingTop: 10 }]) },
                    React.createElement(Panel, { shaded: true, style: reactNative.StyleSheet.flatten([styles.statsStyle, { backgroundImage: `linear-gradient(${ele.color1 ? ele.color1 : COLOR1}, ${ele.color2 ? ele.color2 : COLOR2})` },
                            props.cardContainerStyle]) },
                        React.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.cardStyle, props.cardStyle]) },
                            React.createElement(reactIconRs.IconV, { icons: ele.icon }),
                            React.createElement(reactNative.View, null,
                                React.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([styles.titleStyle, Object.assign({}, ele.titleStyle)]) }, ele.label),
                                React.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([styles.textStyle, Object.assign({}, ele.textStyle)]) }, ele.count))))));
            });
        return cards;
    };
    return (React.createElement(reactFlexboxGridRs.FBGridV, { justify: "center" }, _getCards(props.items)));
};

exports.DashboardCardsV = DashboardCardsV;
