//@ts-nocheck
import React from 'react';
import {TextStyle} from 'react-native';
import {Text} from 'react-native-elements';

interface AppProps {
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  h4?: boolean;
  h1Style?: TextStyle;
  h2Style?: TextStyle;
  h3Style?: TextStyle;
  h4Style?: TextStyle;
  style?: any;
  numberOfLines?: number;
  align: 'auto' | 'left' | 'right' | 'center' | 'justify';
  color: string;
  type:
    | 'body'
    | 'callout'
    | 'caption'
    | 'caption'
    | 'footnote'
    | 'headline'
    | 'subhead'
    | 'title1'
    | 'title2'
    | 'title3';
  ellipsizeMode?: 'head' | 'middle' | 'tail' | 'clip';
  children: React.ReactNode;
}

export class TextV extends React.Component<AppProps> {
  static defaultProps = {
    type: 'body',
    color: '#000000',
    align: 'left',
  };

  render() {
    const typography = styles.typography[this.props.type];
    const color = (() => {
      if (this.props.color) {
        return this.props.color;
      } else if (typography.color && typeof typography.color === 'string') {
        return typography.color;
      }
    })();
    const computedStyle = [typography, {textAlign: this.props.align, color}];
    this.props.style && computedStyle.push(this.props.style);
    return <Text {...this.props} style={computedStyle} />;
  }
}

// export const TextV = (props: AppProps): JSX.Element => {
//   const typography = styles.typography[props.type];
//   const color = (() => {
//     if (props.color) {
//       return props.color;
//     } else if (typography.color && typeof typography.color === "string") {
//       return typography.color;
//     }
//   })();
//   const computedStyle = [typography, { textAlign: props.align, color }];
//   props.style && computedStyle.push(props.style);
//   return <Text {...props} style={computedStyle} />;
// };

const styles = {
  typography: {
    body: {
      fontSize: 17,
      lineHeight: 20,
    },
    callout: {
      fontSize: 16,
      lineHeight: 20,
    },
    caption: {
      fontSize: 11,
      lineHeight: 13,
    },
    footnote: {
      fontSize: 13,
      lineHeight: 18,
      color: '#999999',
    },
    headline: {
      fontSize: 17,
      lineHeight: 22,
    },
    subhead: {
      fontSize: 15,
      lineHeight: 20,
    },
    title1: {
      fontSize: 34,
      lineHeight: 41,
    },
    title2: {
      fontSize: 28,
      lineHeight: 34,
    },
    title3: {
      fontSize: 22,
      lineHeight: 26,
    },
  },
};
