'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var React = require('react');
var React__default = _interopDefault(React);
var reactNative = require('react-native');
var reactViewportProvider = require('react-viewport-provider');
require('rsuite/dist/styles/rsuite-default.css');
var Container = _interopDefault(require('rsuite/lib/Container'));
var Content = _interopDefault(require('rsuite/lib/Content'));
var Footer = _interopDefault(require('rsuite/lib/Footer'));
var Avatar = _interopDefault(require('rsuite/lib/Avatar'));
var Badge = _interopDefault(require('rsuite/lib/Badge'));
var Divider = _interopDefault(require('rsuite/lib/Divider'));
var Dropdown = _interopDefault(require('rsuite/lib/Dropdown'));
var Header = _interopDefault(require('rsuite/lib/Header'));
var Icon = _interopDefault(require('rsuite/lib/Icon'));
var Nav = _interopDefault(require('rsuite/lib/Nav'));
var Navbar = _interopDefault(require('rsuite/lib/Navbar'));
var FlexboxGrid = _interopDefault(require('rsuite/lib/FlexboxGrid'));
var IconButton = _interopDefault(require('rsuite/lib/IconButton'));
var Sidebar = _interopDefault(require('rsuite/lib/Sidebar'));
var Sidenav = _interopDefault(require('rsuite/lib/Sidenav'));

const ids = require('short-id');
const FooterV = (props) => {
    const { width, height } = reactViewportProvider.useViewport();
    const styles = {
        footerStyle: {
            borderTopWidth: 1,
            borderColor: '#C1C1C1',
            boxShadow: '#C1C1C1',
            width: 1 * width - (props.sidebarWidth ? props.sidebarWidth : 0),
            marginLeft: props.sidebarWidth ? props.sidebarWidth : 0,
            height: 0.02 * height,
        },
    };
    const renderFooter = () => {
        let _footer = [];
        if (props.renderFooter) {
            _footer.push(React__default.createElement(reactNative.View, { key: ids.generate(), style: reactNative.StyleSheet.flatten([styles.footerStyle]) }, props.renderFooter()));
        }
        return _footer;
    };
    return React__default.createElement(Footer, null, renderFooter());
};

//@ts-nocheck
const ids$1 = require('short-id');
const NAV_BAR_BG_COLOR = '#F4F4F4';
const RIGHT_AVATAR_BG_COLOR = '#FFFFFF';
const TITLE_COLOR = '#717171';
const NAV_BAR_HEIGHT = 56;
const HeaderV = (props) => {
    const [activeKey, setActiveKey] = React.useState(null);
    const styles = {
        navbarStyle: {
            backgroundColor: NAV_BAR_BG_COLOR,
            boxShadow: '1px 1px #C1C1C1',
        },
        avatarStyle: {
            background: 'transparent',
            width: 100,
        },
        headerStyle: {
            position: 'fixed',
            top: 0,
            display: 'block',
            width: '100%',
            zIndex: 9
        },
        headerTitleContainerStyle: {
            alignItems: 'center',
            justifyContent: 'center',
            height: NAV_BAR_HEIGHT,
        },
        headerTitleStyle: {
            color: TITLE_COLOR,
            fontWeight: 'bold',
            fontSize: 22,
        },
        rightAvatarStyle: {
            background: RIGHT_AVATAR_BG_COLOR,
            marginTop: 12,
            color: TITLE_COLOR,
            cursor: "pointer"
        },
        navItemTitleStyle: {
            color: TITLE_COLOR,
            fontWeight: '500',
        },
        navbarItemIconStyle: {
            color: TITLE_COLOR,
        },
    };
    const renderNavbarHeader = () => {
        const _header = [];
        if (props.headerLogo) {
            _header.push(React__default.createElement(Avatar, { key: ids$1.generate(), circle: true, src: props.headerLogo, size: 'lg', style: reactNative.StyleSheet.flatten([
                    styles.avatarStyle,
                    props.headerLogoStyle,
                ]) }));
        }
        return _header;
    };
    const renderIcon = (icon, eventKey, badge) => {
        if (icon) {
            if (badge) {
                return (React__default.createElement(Badge, { content: badge },
                    React__default.createElement(Icon, Object.assign({}, icon, { icon: icon === null || icon === void 0 ? void 0 : icon.name, style: reactNative.StyleSheet.flatten([
                            styles.navbarItemIconStyle,
                            icon.style,
                        ]) }))));
            }
            return (React__default.createElement(Icon, Object.assign({}, icon, { icon: icon === null || icon === void 0 ? void 0 : icon.name, style: reactNative.StyleSheet.flatten([
                    styles.navbarItemIconStyle,
                    icon.style,
                ]) })));
        }
        return null;
    };
    const renderDropDownItems = (items) => {
        let _items = [];
        if (items && Array.isArray(items)) {
            for (let i = 0; i < items.length; ++i) {
                _items.push(React__default.createElement(Dropdown.Item, { key: ids$1.generate(), eventKey: items[i].eventKey },
                    items[i].title && (React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                            styles.navItemTitleStyle,
                            activeKey === items[i].eventKey &&
                                props.primaryColor && {
                                color: props.primaryColor,
                            },
                        ]) }, items[i].title)),
                    items[i].divider && (React__default.createElement(reactNative.View, { style: { marginTop: 15 } },
                        React__default.createElement(Divider, { style: { padding: 0, margin: 0 } })))));
            }
        }
        return _items;
    };
    const renderNavBarRightItems = () => {
        var _a, _b, _c;
        let _items = [];
        if (props.navBarRightItems && Array.isArray(props.navBarRightItems)) {
            for (let i = 0; i < props.navBarRightItems.length; ++i) {
                const _item = props.navBarRightItems[i];
                if (_item.appearance === 'avatar') {
                    _items.push(React__default.createElement(Avatar, { key: ids$1.generate(), circle: true, src: (_a = _item.avatar) === null || _a === void 0 ? void 0 : _a.src, alt: (_b = _item.avatar) === null || _b === void 0 ? void 0 : _b.title, size: 'sm', style: reactNative.StyleSheet.flatten([styles.rightAvatarStyle]), onClick: () => props.onNavItemSelect && props.onNavItemSelect(_item.eventKey) }, (_c = _item.avatar) === null || _c === void 0 ? void 0 : _c.title));
                }
                if (_item.appearance === 'drop-down') {
                    _items.push(React__default.createElement(Dropdown, { key: ids$1.generate(), title: _item.title }, renderDropDownItems(_item.dropdownItems)));
                }
                else {
                    _items.push(React__default.createElement(Nav.Item, { key: ids$1.generate(), eventKey: _item.eventKey, icon: renderIcon(_item.icon, _item.eventKey, _item.badge) }, _item.title && (React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                            styles.navItemTitleStyle,
                        ]) }, _item.title))));
                }
            }
        }
        return _items;
    };
    const handleNavRightItemSelect = (activeKey) => {
        setActiveKey(activeKey);
        props.onNavItemSelect && props.onNavItemSelect(activeKey);
    };
    return (React__default.createElement(Header, { style: reactNative.StyleSheet.flatten([styles.headerStyle]) },
        React__default.createElement(Navbar, { style: reactNative.StyleSheet.flatten([styles.navbarStyle, props.navbarStyle]) },
            React__default.createElement(Navbar.Header, null, renderNavbarHeader()),
            React__default.createElement(Navbar.Body, null,
                React__default.createElement(Nav, null, props.headerTitle && (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([styles.headerTitleContainerStyle]) },
                    React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                            styles.headerTitleStyle, props.headerTitleStyle,
                        ]) }, props.headerTitle)))),
                React__default.createElement(Nav, { pullRight: true, activeKey: activeKey, onSelect: handleNavRightItemSelect }, renderNavBarRightItems())))));
};

//@ts-nocheck
const ids$2 = require('short-id');
const EXPANDABLE_WIDTH = 260;
const COLLAPSIBLE_WIDTH = 56;
const IS_SIDEBAR_COLLAPSIBLE = true;
const SIDE_BAR_APPERANCE = 'default';
const NAV_BAR_BG_COLOR$1 = '#F4F4F4';
const RIGHT_AVATAR_BG_COLOR$1 = '#FFFFFF';
const TITLE_COLOR$1 = '#C1C1C1';
const NAV_BAR_HEIGHT$1 = 56;
const DROP_DOWN_PLACEMENT = 'right-start';
const SidebarV = (props) => {
    const [expand, setExpand] = React.useState(true);
    const [activeKey, setActiveKey] = React.useState(null);
    const [hover, setHover] = React.useState({});
    const { height } = reactViewportProvider.useViewport();
    const styles = {
        sidebar: {
            position: 'fixed',
            top: 56,
            height: '100vh',
            paddingBottom: 100,
            display: 'flex',
            flexDirection: 'column',
            // minHeight: 0.92 * height,
            backgroundColor: props.primaryColor,
            zIndex: 9,
            overflowY: 'auto',
            overflowX: 'hidden',
        },
        navbarStyle: {
            backgroundColor: NAV_BAR_BG_COLOR$1,
            boxShadow: '1px 1px #C1C1C1',
        },
        avatarStyle: {
            background: 'transparent',
            width: 100,
        },
        headerStyle: {},
        headerTitleContainerStyle: {
            alignItems: 'center',
            justifyContent: 'center',
            height: NAV_BAR_HEIGHT$1,
        },
        headerTitleStyle: {
            color: TITLE_COLOR$1,
            fontWeight: 'bold',
            fontSize: 22,
        },
        rightAvatarStyle: {
            background: RIGHT_AVATAR_BG_COLOR$1,
            marginTop: 12,
            color: TITLE_COLOR$1,
        },
        navItemTitleStyle: {
            color: TITLE_COLOR$1,
            fontWeight: '500',
        },
        navbarItemIconStyle: {
            color: TITLE_COLOR$1,
        },
        sidenavBodyStyle: {
            backgroundColor: props.primaryColor,
        },
        sidebarClosureIconStyle: {
            width: 30,
            backgroundColor: 'transparent',
            // boxShadow: '2px 2px #C1C1C1',
            borderColor: '#000000',
        },
    };
    const renderHeader = () => {
        const _header = [];
        if (props.renderSidenavHeader) {
            _header.push(React__default.createElement(Sidenav.Header, { key: ids$2.generate() }, props.renderSidenavHeader()));
        }
        return _header;
    };
    const handleSideNavItemSelect = (activeKey) => {
        setActiveKey(activeKey);
        props.onSideNavItemSelect && props.onSideNavItemSelect(activeKey);
    };
    const renderDropDownItems = (items) => {
        let _items = [];
        if (items && Array.isArray(items)) {
            for (let i = 0; i < items.length; ++i) {
                _items.push(React__default.createElement(Dropdown.Item, { key: ids$2.generate(), eventKey: items[i].eventKey },
                    items[i].title && (React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                            styles.navItemTitleStyle,
                            activeKey === items[i].eventKey &&
                                props.ternaryColor && {
                                color: props.ternaryColor,
                            },
                        ]) }, items[i].title)),
                    items[i].divider && (React__default.createElement(reactNative.View, { style: { marginTop: 15 } },
                        React__default.createElement(Divider, { style: { padding: 0, margin: 0 } })))));
            }
        }
        return _items;
    };
    const renderIcon = (icon, eventKey, badge) => {
        if (icon) {
            if (badge) {
                return (React__default.createElement(Badge, { content: badge },
                    React__default.createElement(Icon, Object.assign({}, icon, { icon: icon === null || icon === void 0 ? void 0 : icon.name, style: reactNative.StyleSheet.flatten([
                            styles.navbarItemIconStyle,
                            icon.style,
                            activeKey === eventKey &&
                                props.secondaryColor && { color: props.secondaryColor },
                        ]) }))));
            }
            return (React__default.createElement(Icon, Object.assign({}, icon, { icon: icon === null || icon === void 0 ? void 0 : icon.name, style: reactNative.StyleSheet.flatten([
                    styles.navbarItemIconStyle,
                    icon.style,
                    activeKey === eventKey &&
                        props.secondaryColor && { color: props.secondaryColor },
                ]) })));
        }
        return null;
    };
    const handleMouseEnter = (eventKey) => {
        const _hover = Object.assign({}, hover);
        for (let ek in _hover) {
            _hover[ek] = false;
        }
        _hover[eventKey] = true;
        setHover(Object.assign({}, _hover));
    };
    const handleMouseLeave = (eventKey) => {
        const _hover = Object.assign({}, hover);
        for (let ek in _hover) {
            _hover[ek] = false;
        }
        setHover(Object.assign({}, _hover));
    };
    const renderNavItems = () => {
        var _a, _b, _c;
        let _items = [];
        if (props.sideBarItems && Array.isArray(props.sideBarItems)) {
            for (let i = 0; i < props.sideBarItems.length; ++i) {
                const _item = props.sideBarItems[i];
                if (_item.appearance === 'avatar') {
                    _items.push(React__default.createElement(Avatar, { key: ids$2.generate(), circle: true, src: (_a = _item.avatar) === null || _a === void 0 ? void 0 : _a.src, alt: (_b = _item.avatar) === null || _b === void 0 ? void 0 : _b.title, size: 'sm', style: reactNative.StyleSheet.flatten([styles.rightAvatarStyle]), onClick: () => props.onSideNavItemSelect &&
                            props.onSideNavItemSelect(_item.eventKey) }, (_c = _item.avatar) === null || _c === void 0 ? void 0 : _c.title));
                }
                if (_item.appearance === 'drop-down') {
                    _items.push(React__default.createElement(Dropdown, { key: ids$2.generate(), title: _item.title, placement: DROP_DOWN_PLACEMENT }, renderDropDownItems(_item.dropdownItems)));
                }
                else {
                    _items.push(React__default.createElement(Nav.Item, { key: ids$2.generate(), eventKey: _item.eventKey, onMouseEnter: handleMouseEnter.bind(null, _item.eventKey), onMouseLeave: handleMouseLeave.bind(null, _item.eventKey), icon: renderIcon(_item.icon, _item.eventKey, _item.badge), style: reactNative.StyleSheet.flatten([
                            {},
                            activeKey === _item.eventKey &&
                                props.primaryHighlightColor && {
                                backgroundColor: props.primaryHighlightColor,
                            },
                        ]) },
                        React__default.createElement(FlexboxGrid, { justify: 'space-between' },
                            React__default.createElement(FlexboxGrid.Item, { colspan: expand ? 22 : 24 }, _item.title && (React__default.createElement(reactNative.Text, { style: reactNative.StyleSheet.flatten([
                                    styles.navItemTitleStyle,
                                    activeKey === _item.eventKey &&
                                        props.ternaryColor && {
                                        color: props.ternaryColor,
                                    },
                                ]) }, _item.title))),
                            expand && (React__default.createElement(FlexboxGrid.Item, { colspan: 2 }, activeKey === _item.eventKey && props.secondaryColor && (React__default.createElement(Icon, { icon: 'chevron-right', style: { color: props.secondaryColor } })))))));
                }
            }
        }
        return _items;
    };
    const renderSidenav = () => {
        const _sidenav = [];
        if (props.sideBarItems && Array.isArray(props.sideBarItems)) {
            _sidenav.push(React__default.createElement(Sidenav, { expanded: expand, defaultOpenKeys: props.defaultSideBarOpenKeys, appearance: SIDE_BAR_APPERANCE, activeKey: activeKey, onSelect: handleSideNavItemSelect, key: ids$2.generate() },
                React__default.createElement(Sidenav.Body, null,
                    React__default.createElement(Nav, { style: reactNative.StyleSheet.flatten([styles.sidenavBodyStyle]) }, renderNavItems()))));
        }
        return _sidenav;
    };
    const handleToggle = () => {
        props.isexpanded && props.isexpanded(!expand);
        setExpand(!expand);
    };
    const renderFloatingIcon = () => {
        return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
                reactNative.StyleSheet.absoluteFill,
                {
                    top: 10,
                    left: expand ? (props.sidebarWidth || EXPANDABLE_WIDTH) - 32 : COLLAPSIBLE_WIDTH - 22,
                },
            ]) },
            React__default.createElement(IconButton, { icon: React__default.createElement(Icon, { icon: expand ? 'caret-left' : 'caret-right', size: '2x', style: reactNative.StyleSheet.flatten([{}, props.drawerCloseIconColor && { color: props.drawerCloseIconColor }]) }), onClick: handleToggle, style: reactNative.StyleSheet.flatten([styles.sidebarClosureIconStyle]) })));
    };
    return (React__default.createElement(Sidebar, { style: reactNative.StyleSheet.flatten([styles.sidebar]), width: expand ? (props.sidebarWidth || EXPANDABLE_WIDTH) : COLLAPSIBLE_WIDTH, collapsible: IS_SIDEBAR_COLLAPSIBLE },
        renderHeader(),
        renderSidenav(),
        renderFloatingIcon()));
};

const BG_COLOR = '#EAEAEA';
function deleteKeys(obj, keys) {
    if (obj && keys && Array.isArray(keys)) {
        keys.forEach((key) => {
            delete obj[key];
        });
    }
}
const delete_keys = ['sidenavStyle', 'renderHeader', 'renderBody', 'activeKey'];
const DashboardcontainerVInternal = (props) => {
    var _a, _b;
    const [expand, setExpand] = React.useState(true);
    const { width, bp } = reactViewportProvider.useViewport();
    const _props = props ? Object.assign({}, props) : {};
    deleteKeys(_props, delete_keys);
    const styles = {
        container: {
            flex: 1,
            width: 1 * width,
            backgroundColor: BG_COLOR,
            overflowX: 'hidden'
        },
        contentStyle: {
            width: (1 * width) - (expand ? (props.sidebarWidth ? props.sidebarWidth : 260) : 56),
            marginLeft: expand ? (props.sidebarWidth ? props.sidebarWidth : 260) : 56,
            marginTop: 56
        }
    };
    const renderContent = () => {
        return React__default.createElement(Content, { style: reactNative.StyleSheet.flatten([styles.contentStyle]) }, props.renderContent && props.renderContent());
    };
    const renderDashboardLayout = () => {
        if (props.layout === 'sidebar-header-content-footer') {
            return (React__default.createElement(Container, null,
                React__default.createElement(SidebarV, Object.assign({}, props, { isexpanded: eventKey => setExpand(eventKey) })),
                React__default.createElement(Container, null,
                    React__default.createElement(HeaderV, Object.assign({}, props)),
                    renderContent(),
                    React__default.createElement(FooterV, Object.assign({}, props)))));
        }
        if (props.layout === 'header-content-sidebar-footer') {
            return (React__default.createElement(Container, null,
                React__default.createElement(HeaderV, Object.assign({}, props)),
                React__default.createElement(Container, null,
                    renderContent(),
                    React__default.createElement(SidebarV, Object.assign({}, props, { isexpanded: eventKey => setExpand(eventKey) }))),
                React__default.createElement(FooterV, Object.assign({}, props))));
        }
        return (React__default.createElement(Container, null,
            React__default.createElement(HeaderV, Object.assign({}, props)),
            React__default.createElement(Container, null,
                React__default.createElement(SidebarV, Object.assign({}, props, { isexpanded: eventKey => setExpand(eventKey) })),
                renderContent()),
            React__default.createElement(FooterV, Object.assign({}, props))));
    };
    //TO BE IMPLEMENTED DRAWER
    if (bp === 'xs' || bp === 'sm') {
        return React__default.createElement("div", null);
    }
    return (React__default.createElement(reactNative.View, { style: reactNative.StyleSheet.flatten([
            props.dashboardContainerStyle,
            styles.container,
            ((_a = props.dashboardContainerStyle) === null || _a === void 0 ? void 0 : _a.backgroundColor) && {
                backgroundColor: (_b = props.dashboardContainerStyle) === null || _b === void 0 ? void 0 : _b.backgroundColor,
            },
        ]) }, renderDashboardLayout()));
};
const DashboardContainerV = (props) => {
    return (React__default.createElement(reactViewportProvider.ViewportProvider, null,
        React__default.createElement(DashboardcontainerVInternal, Object.assign({}, props))));
};

exports.DashboardContainerV = DashboardContainerV;
