'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var styles = require('@material-ui/core/styles');
var Tooltip = _interopDefault(require('@material-ui/core/Tooltip'));
var React = _interopDefault(require('react'));

//@ts-nocheck
const useStyles = styles.makeStyles({
    tooltip: (style) => (Object.assign({}, style === null || style === void 0 ? void 0 : style.tooltipStyle)),
    arrow: (style) => (Object.assign({}, style === null || style === void 0 ? void 0 : style.arrowStyle)),
});
const TooltipV = ({ tooltipStyle, arrowStyle, arrow, children, disableFocusListener, disableHoverListener, disableTouchListener, enterDelay, enterNextDelay, enterTouchDelay, id, interactive, onClose, onOpen, leaveDelay, leaveTouchDelay, open, placement, PopperComponent, PopperProps, title, TransitionComponent, TransitionProps, renderItem, }) => {
    const classes = useStyles({ tooltipStyle, arrowStyle });
    const CustomComponent = React.forwardRef(function CustomComponent(props, ref) {
        //  Spread the props to the underlying DOM element.
        return (React.createElement("div", Object.assign({}, props, { ref: ref }), children &&
            children.map((child, index) => renderItem && renderItem(child, index))));
    });
    return (React.createElement(Tooltip, { arrow: arrow, disableFocusListener: disableFocusListener, disableHoverListener: disableHoverListener, disableTouchListener: disableTouchListener, enterDelay: enterDelay, enterTouchDelay: enterTouchDelay, enterNextDelay: enterNextDelay, id: id, interactive: interactive, onClose: onClose, onOpen: onOpen, leaveDelay: leaveDelay, leaveTouchDelay: leaveTouchDelay, open: open, placement: placement, PopperComponent: PopperComponent, PopperProps: PopperProps, title: title, TransitionComponent: TransitionComponent, TransitionProps: TransitionProps, classes: { tooltip: classes.tooltip, arrow: classes.arrow } },
        React.createElement(CustomComponent, null)));
};

exports.TooltipV = TooltipV;
