import React from 'react';
import {ViewStyle} from 'react-native';
import {Icon} from 'react-native-elements';

interface AppProps {
  color?: string;
  iconStyle?: ViewStyle;
  Component?: any;
  disabled?: boolean;
  name: string;
  disabledStyle?: ViewStyle;
  onPress?(): void;
  onLongPress?(): void;
  underlayColor?: string;
  reverse?: boolean;
  raised?: boolean;
  containerStyle?: ViewStyle;
  reverseColor?: string;
  type?: string;
  size?: number;
}

export const IconV = (props: AppProps): JSX.Element => {
  return <Icon {...props} />;
};
